import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['modal', 'openButton']

  close(event) {
    // Ignore clicks inside modal open button and modal itself
    if (
      this.modalTarget.contains(event.target)
      || this.openButtonTarget.contains(event.target)
    ) return

    this.modalTarget.close()
    this.dispatch('close')
  }

  open() {
    this.modalTarget.showModal()
    this.dispatch('open')
  }
}
