import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    overlayShown: { default: false, type: Boolean },
    scrolled: { default: false, type: Boolean }
  }

  static targets = ['mobileDropdown']

  connect() {
    this.toggleScrolled()
  }

  hideOverlay() {
    this.overlayShownValue = false
  }

  toggleOverlay() {
    this.overlayShownValue =
      this.mobileDropdownTarget.getAttribute('data-dropdown-active-value')
  }

  toggleScrolled() {
    const scrollTop = window.scrollY || document.documentElement.scrollTop
    this.scrolledValue = scrollTop > 0
  }
}
