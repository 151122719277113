import PricingController from '../pricing_controller.js'
import debounce from 'debounce'

export default class extends PricingController {
  static values = { packages: Array }
  static targets = [
    'costPerCredit', 'credits', 'input', 'monthlyPrice', 'payAsYouGoPrice',
    'savings', 'selectBox', 'selectMenu', 'toggle'
  ]

  connect() {
    this.maxCredits = this.packagesValue.slice(-1)
    this.handlePriceRequest = debounce(this.handlePriceRequest, 200)

    // get the price for the lowest package on page load
    this.handlePriceRequest(this.packagesValue[0])
  }

  updatePrice(event) {
    if (this.selectBoxInvalidKeyPress(event)) return

    // get the credit value of whatever control the user interacted with and
    // bind all the controls to that value
    const credits = this.getUserInputValue(event)
    this.#bindControlValues(credits)

    if (this.element.dataset.enterpriseVisible === 'true') return

    this.handlePriceRequest(credits)
  }

  // rounds the user's input UP to the nearest thousand credits on blur
  roundUserInput() {
    this.inputTarget.value =
      parseInt(this.roundedCredits).toLocaleString()

    this.#bindControlValues(this.roundedCredits)
  }

  togglePaymentFrequency() {
    const { checked } = this.toggleTarget

    if (checked) {
      this.costPerCreditTarget.textContent = this.monthlyCostPerCredit
    } else {
      this.costPerCreditTarget.textContent = this.paygCostPerCredit
    }

    this.element.dataset.defaultPriceVisible = !checked
  }

  async handlePriceRequest(credits) {
    const data = await this.requestPrice('credits', credits)

    this.paygCostPerCredit = data.payg.cpc.formatted
    this.monthlyCostPerCredit = data.subscription.cpc.formatted

    this.monthlyPriceTarget.textContent = data.subscription.amount.formatted
    this.payAsYouGoPriceTarget.textContent = data.payg.amount.formatted
    this.savingsTarget.textContent = data.subscription.savings.formatted
    this.creditsTarget.textContent = data.credits.toLocaleString()
    this.costPerCreditTarget.textContent = this.toggleTarget.checked
      ? data.payg.cpc.formatted
      : data.subscription.cpc.formatted

    // store the credit amount rounded to the nearest thousand to set on blur
    // by roundUserInput
    this.roundedCredits = credits > this.maxCredits ? credits : data.credits

    this.updateCurrency(data.currency.iso_code)
  }

  #bindControlValues(credits) {
    // format the input's value
    this.inputTarget.value = credits.toLocaleString()

    const packageTier = this.#getPackageTier(credits)

    // deselect all select boxes and reset the select menu's value if the
    // credits do not match a package exactly
    if (['enterprise', null].includes(packageTier)) {
      this.selectMenuTarget.selectedIndex = 0
    } else {
      this.selectMenuTarget.value = packageTier
    }

    this.selectBoxTargets.forEach(box => {
      box.dataset.selected = box.dataset.credits === packageTier?.toString()
    })

    // show the enterprise prompt if user's input exceeds highest package
    const isEnterprise = packageTier === 'enterprise'
    this.element.dataset.enterpriseVisible = isEnterprise
  }

  #getPackageTier(credits) {
    if (credits > this.maxCredits) {
      // store the rounded credits so it can be accessed and set as the input's
      // value when the input loses focus
      this.roundedCredits = Math.round(credits / 1000) * 1000
      return 'enterprise'
    }

    // only return a package tier if the user's input is an exact match
    let selectedTier = null

    this.packagesValue.forEach(tier => {
      if (tier === credits) selectedTier = tier
    })

    return selectedTier
  }
}
