import { Controller } from '@hotwired/stimulus'
import { useDebounce } from 'stimulus-use'

export default class extends Controller {
  static debounces = ['setDimensions', { wait: 100 }]
  static targets = ['item']
  static values = {
    responsive: { type: Boolean, default: true },
    height: { type: Boolean, default: true },
    width: { type: Boolean, default: true }
  }

  connect() {
    this.setDimensions()
    if (!this.responsiveValue) return

    useDebounce(this)
    this.setDimensions = this.setDimensions.bind(this)
    window.onresize = this.setDimensions
  }

  disconnect() {
    window.removeEventListener('resize', this.setDimensions.bind(this))
  }

  #getLargestDimension(dimension) {
    let maxValue = 0

    this.itemTargets.forEach(item => {
      if (dimension === 'height') {
        const itemHeight = item.getBoundingClientRect().height
        if (itemHeight > maxValue) maxValue = itemHeight
      } else if (dimension === 'width') {
        const itemWidth = item.getBoundingClientRect().width
        if (itemWidth > maxValue) maxValue = itemWidth
      }
    })

    return maxValue
  }

  setDimensions() {
    let height, width
    if (this.heightValue) { height = this.#getLargestDimension('height') }
    if (this.widthValue) { width = this.#getLargestDimension('width') }

    if (this.heightValue && this.widthValue) {
      this.element.style.height = `${height}px`
      this.element.style.width = `${width}px`
    } else if (this.heightValue && !this.widthValue) {
      this.element.style.height = `${height}px`
    } else if (!this.heightValue && this.widthValue) {
      this.element.style.width = `${width}px`
    }
  }
}
